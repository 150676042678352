import { AUTHENTICATED, UNAUTHENTICATED } from '@/const/auth'
import { HTTP_CODES } from '@/const/http'
import { reportError } from '@/services/utils/telemetry'
import { showToast, toastStatus } from '@/services/utils/toast'
import { reactive, toRefs } from 'vue'
import { useAuth } from '../firebase/auth'

const getIdToken = () => {
  const { auth } = useAuth().authState
  return auth.currentUser.getIdToken()
}

const tokenHeader = async () => {
  const token = await getIdToken()

  return { 'Firebase-Token': `Bearer ${token}` }
}

export const contentHeader = {
  'Content-Type': 'application/json'
}

const constructHttpOptions = (method, headers, payload = null) => {
  return {
    method,
    mode: 'cors',
    headers,
    ...(payload && { body: JSON.stringify(payload) })
  }
}

export const constructOptions = async (method, payload = null) => {
  return Promise.resolve(constructHttpOptions(method, contentHeader, payload))
}

export const constructAuthOptions = async (method, payload = null) => {
  const headers = await tokenHeader()
  return constructHttpOptions(method, headers, payload)
}

const apiResult = () =>
  reactive({
    loading: false,
    data: null,
    contentType: '',
    error: {},
    statusCode: null
  })

const jsonResult = async (response) => await response.json()
export const fileResult = async (response) => await response.blob()
export const textResult = async (response) => await response.text()
export const libertyGetOptions = { optionsHandler: constructAuthOptions, resultGetter: jsonResult };
export const spnkApiOptions = {
  optionsHandler: constructOptions,
  resultGetter: jsonResult,
  requiredAuth: false
};

export const useApi = ({
  optionsHandler,
  requiredAuth = true,
  resultGetter = jsonResult,
  toastMgs = true
}) => {
  const result = apiResult()
  const { authState } = useAuth()
  const fetchApi = async ({ endPoint, method, payload }) => {
    result.loading = true
    const httpOptions = await optionsHandler(method, payload)
    let response

    try {
      response = await fetch(endPoint, httpOptions)
      result.statusCode = response.status

      if (response.ok) {
        if (requiredAuth && AUTHENTICATED !== authState.authenticated) {
          authState.authenticated = AUTHENTICATED
        }
        result.contentType = response.headers.get('Content-Type')
        result.data = await resultGetter(response)
      } else {
        const error = await response.text()
        result.data = null
        result.error = error
        reportError(error, { endPoint, method, payload: JSON.stringify(payload) })

        if (HTTP_CODES.FORBIDDEN === response.status && requiredAuth) {
          authState.authenticated = UNAUTHENTICATED
          return
        }
        if (toastMgs)
          showToast(
            'Some problems have occurred on our end. Please try again later.',
            toastStatus.ERROR
          )
      }
    } catch (error) {
      result.error = error
      result.data = null
      reportError(error, {
        endPoint,
        method,
        payload: JSON.stringify(payload),
        response: JSON.stringify(response ?? '')
      })

      if (toastMgs) {
        showToast('Network issue! Please try again later.', toastStatus.ERROR)
      }
    } finally {
      result.loading = false
    }
  }

  return { ...toRefs(result), fetchApi }
}
