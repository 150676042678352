export const AUTH_UNKNOWN = 'UNKNOWN'
export const AUTHENTICATED = 'AUTHENTICATED'
export const UNAUTHENTICATED = 'UNAUTHENTICATED'

export const LOGIN_STATE = {
  WAITING_FOR_EMAIL: 'WAITING_FOR_EMAIL',
  EMAIL_LINK_SENT: 'EMAIL_LINK_SENT',
  REDIRECTING: 'REDIRECTING'
}

export const CUSTOM_OAUTH_PARAMETERS = {
  hd: 'reach.nz',
  prompt: 'select_account'
}

export const USER_ROLES = {
  ORG_ADMIN: 'ORG_ADMIN',
  CALL_CENTRE: 'CALL_CENTR',
  REACH: 'REACH'
}
