import { DELIVERY_STATUS } from "@/const/filter";
import { ROUTES } from "@/const/route";
import { isString } from "lodash-es";

export const queryChecker = {
    [ROUTES.DELIVERY_STATUS]: (query) => {
        let statuses = query.status || []
        if (isString(statuses)) statuses = [statuses]
        return statuses.every((status) => Object.values(DELIVERY_STATUS).includes(status))
    }
}