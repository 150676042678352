export const SORT_ORDER = {
	ASCENDING: 'Ascending',
	DESCENDING: 'Descending',
}

export const DELIVERY_SORT_PARAM = {
	ROUND_NAME: 'walk_name',
	SUPERVISOR: 'supervisor_name',
	ADVICE_NUMBER: 'advice_number',
	COMPLETION_DATE: 'completion_date'
}