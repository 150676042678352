import { AVAILABLE_ROUTES, ROUTES } from '@/const/route'
import { getPermissions } from '@/services/apis/permission'
import { useAuth } from '@/services/firebase/auth'
import { isObjHasProperties, removeNonExistentPropertiesWithDiff } from '@/services/helpers/common'
import { useMainStore } from '@/stores/main'
import { createRouter, createWebHistory } from 'vue-router'
import { queryChecker } from './common'

const { isUserSignedIn } = useAuth()

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: AVAILABLE_ROUTES,
  strict: true,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 }
  }
})

router.beforeEach(async (to) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  const requireQueryCheck = to.matched.some((record) => record.meta.requireQueryCheck)
  const signedIn = await isUserSignedIn()
  const mainStore = useMainStore()

  if (signedIn) {
    try {
      if (!mainStore.permissions) {
        const permissions = await getPermissions()

        mainStore.setPermissions(permissions)
      }

      if (!mainStore.permissions) {
        if (to.name !== ROUTES.NO_PERMISSION) {
          return { name: ROUTES.NO_PERMISSION }
        }
      }

      if (to.name !== ROUTES.NO_PERMISSION) {
        if (!mainStore.hasAppPermission) {
          return { name: ROUTES.NO_PERMISSION }
        }

        if (to.meta.requiredRoles && !mainStore.hasFeatureRoles(to.meta.requiredRoles)) {
          return { name: ROUTES.NO_PERMISSION }
        }
      } else {
        // Redirect to home if permission is granted
        if (mainStore.hasAppPermission) {
          return ROUTES.BASE
        }
      }

      if (to.name === ROUTES.LOGIN || to.name === `${ROUTES.LOGIN}/${ROUTES.REACH}`) {
        if (to.query?.from) {
          return to.query.from
        }
        return `${ROUTES.BASE}${ROUTES.TICKETS}`
      }

      if (to.name === ROUTES.BASE) {
        return `${ROUTES.BASE}${ROUTES.TICKETS}`
      }

      if (requireQueryCheck) {
        const { defaultFilter } = to.meta
        const { filtered, removed } = removeNonExistentPropertiesWithDiff(to.query, defaultFilter)

        if (!isObjHasProperties(filtered, defaultFilter)) {
          return {
            name: to.name,
            query: defaultFilter
          }
        }
        if (Object.keys(removed).length !== 0) {
          return {
            name: to.name,
            query: filtered
          }
        }
        const { checkerKey } = to.meta
        if (checkerKey && !queryChecker[checkerKey](to.query)) {
          return {
            name: to.name,
            query: defaultFilter
          }
        }
      }
    } catch (error) {
      if (to.name !== ROUTES.NO_PERMISSION) {
        return { name: ROUTES.NO_PERMISSION }
      }
    }
  } else {
    if (requiresAuth) {
      const loginPath = window.location.pathname

      return { name: ROUTES.LOGIN, query: { from: loginPath } }
    }
  }
})

export default router
