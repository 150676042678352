export const HTTP = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT'
}

const ociGateway = import.meta.env.VITE_OCI_GATEWAY_URL
const geoGateway = import.meta.env.VITE_GEO_GATEWAY_URL
const customerOciGateway = `${ociGateway}/customer`
export const ENDPOINT = {
  // Get list of jobs with start/end date or walk ID
  GET_JOBS: (q) => `${ociGateway}/tickets/jobsearch?${q}`,
  GET_JOB: (id) => `${ociGateway}/tickets/jobsearch?job_id=${id}`,
  GET_TICKETS: () => `${ociGateway}/tickets/`,
  GET_TICKET: (id) => `${ociGateway}/tickets/${id}`,
  CREATE_TICKET: () => `${ociGateway}/tickets/create`,
  UPDATE_TICKET: (id) => `${ociGateway}/tickets/${id}`,
  GET_COMMENTS: (id) => `${ociGateway}/tickets/${id}/comments`,
  CREATE_COMMENT: (id) => `${ociGateway}/tickets/${id}/comments`,
  GET_ATTACHMENT: (id) => `${ociGateway}/tickets/attachment/${id}`,
  CREATE_ATTACHMENT: (id) => `${ociGateway}/tickets/comments/${id}/attachment`,
  GET_ROUND_BY_LATLNG: (lat, lng) => `${geoGateway}/roundByGeoPoint?lat=${lat}&lng=${lng}`,
  GET_ROUND_BOX: () => `${geoGateway}/roundsBoundingBox`,
  GET_ROUND_MAP: () => `${geoGateway}/roundsByList`,
  GET_ROUND_LIST: () => `${ociGateway}/rounds/`,
  GET_ROUND_DETAILS: (walkId) => `${ociGateway}/rounds/${walkId}`,
  GET_PERMISSIONS: () => `${ociGateway}/user/permissions`,
  GET_PRINT_QUOTES: (q) => `${ociGateway}/calculator/print?${q}`,
  GET_CUSTOMER: (searchTerm) => `${ociGateway}/customer/search/${searchTerm}`,
  GET_CUSTOMER_CONTACTS: (cmsCode) => `${ociGateway}/customer/contacts/${cmsCode}`,
  GET_LETTERBOX_TOTAL: (q) => `${ociGateway}/calculator/total?${q}`,
  GET_LETTERBOX_ADMIN_FEE: (q) => `${ociGateway}/rates/admin?${q}`,
  GET_LETTERBOX_FARMER_QUOTE: (q) => `${ociGateway}/rates/farmer-premium?${q}`,
  GET_LETTERBOX_FREIGHT_QUOTE: (q) => `${ociGateway}/calculator/freight?${q}`,
  GET_LETTERBOX_NETWORK_QUOTE: (q) => `${ociGateway}/rates/network-management?${q}`,
  GET_LETTERBOX_VOLUME_QUOTE: (q) => `${ociGateway}/calculator/volume?${q}`,
  GET_ACCOUNT_CODE: (code) => `${ociGateway}/customer/by-account-code/${code}`,
  CREATE_STANDALONE_JOB: () => `${ociGateway}/jobs/create`,
  UPDATE_STANDALONE_JOB: (quoteId) => `${ociGateway}/jobs/${quoteId}`,
  DELETE_STANDALONE_JOB: (quoteId) => `${ociGateway}/jobs/${quoteId}`,
  GET_DELIVERY_COMPLETION: ({ view, queryParams }) =>
    `${customerOciGateway}/completion/list/${view}?${new URLSearchParams(queryParams).toString()}`,
  GET_DELIVERY_COMPLETION_BY_CMS_CODE: ({cmsCode, view, queryParams }) =>
    `${customerOciGateway}/${cmsCode}/completion/list/${view}?${new URLSearchParams(queryParams).toString()}`,
  GET_JOBS_INFO: ({ view, queryParams }) => 
    `${customerOciGateway}/jobs/list/${view}?${new URLSearchParams(queryParams).toString()}`,
  GET_JOBS_BY_CMS_CODE: ({cmsCode, view, queryParams }) =>
    `${customerOciGateway}/${cmsCode}/jobs/list/${view}?${new URLSearchParams(queryParams).toString()}`
}

export const HTTP_CODES = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  SERVER_ERROR: 500
}
