import { shallowReactive } from 'vue'

const OPEN = 'OPEN'
const CLOSE = 'CLOSE'

export const toastStatus = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING: 'warning'
}

export const toastState = shallowReactive({
  visible: CLOSE,
  text: '',
  status: toastStatus.INFO
})

export const showToast = (text, status = toastStatus.INFO, duration = 4000) => {
  toastState.visible = CLOSE
  toastState.text = text
  toastState.status = status
  toastState.visible = OPEN
  setTimeout(() => (toastState.visible = CLOSE), duration)
}

export const isToastClose = () => {
  return toastState.visible === CLOSE
}

export const closeToast = () => (toastState.visible = CLOSE)

export const formatMultilineToastText = (texts) => {
  return texts.map((t) => `<p>${t}</p>`).join('')
}
