import * as Sentry from '@sentry/vue'

export const reportError = (error, extra) => {
  Sentry.captureException(error, { extra: extra })
}

export const setTelemetryUser = (user) => {
  if (user) {
    Sentry.setUser({
      id: user.id,
      username: user.name,
      email: user.email
    })
  } else {
    Sentry.setUser(null)
  }
}
