import { getDeliveryFilter } from '@/services/factory/searchSortFilter'
import { USER_ROLES } from './auth'

export const ROUTES = {
  // Auth
  LOGIN: 'login',
  REACH: 'reach', // Staff login
  NO_PERMISSION: 'setup',
  // Pages
  BRIEFS: 'briefs',
  CALCULATOR: 'calculator',
  COMPANY: 'company',
  CONTACT: 'contact',
  CUSTOMER: 'customer',
  DESIGN: 'design',
  DIGITAL: 'digital',
  DOWNLOAD: 'download',
  LETTERBOX: 'letterbox',
  PRINT: 'print',
  QUOTES: 'quotes',
  SOCMED: 'social_media_management',
  WEBSITE: 'website',
  DASHBOARD: 'dashboard',
  JOBS: 'jobs',
  TICKETS: 'tickets',
  CREATE_TICKET: 'create_ticket',
  DELIVERY_STATUS: 'deliveryStatus',
  CHECK_DELIVERY_ADDRESS: 'checkDeliveryAddress',
  DELIVERY_REPORT: 'deliveryReport',
  // Generic
  BASE: '/',
  ERROR: 'error',
  NEW: 'new' // Used for creating new objects
}

export const AVAILABLE_ROUTES = [
  {
    meta: {
      title: 'Set up account',
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.NO_PERMISSION}`,
    name: ROUTES.NO_PERMISSION,
    component: () => import('@/views/NoPermissionView.vue')
  },
  {
    meta: {
      title: 'Briefs',
      requiredRoles: [USER_ROLES.REACH],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.BRIEFS}`,
    name: 'Briefs',
    component: () => import('@/views/Brief/BriefsView.vue')
  },
  {
    meta: {
      title: 'Brief',
      requiredRoles: [USER_ROLES.REACH],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.BRIEFS}/:id`,
    name: 'Brief',
    component: () => import('@/views/Brief/BriefView.vue')
  },
  {
    meta: {
      title: 'Edit Company',
      requiredRoles: [USER_ROLES.REACH],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.BRIEFS}/:id/${ROUTES.COMPANY}`,
    name: 'Edit Company',
    component: () => import('@/views/Brief/BriefCompanyView.vue')
  },
  {
    meta: {
      title: 'Edit Contact',
      requiredRoles: [USER_ROLES.REACH],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.BRIEFS}/:id/${ROUTES.CONTACT}`,
    name: 'Edit Contact',
    component: () => import('@/views/Brief/BriefContactView.vue')
  },
  {
    path: ROUTES.BASE,
    redirect: { path: `${ROUTES.BASE}${ROUTES.TICKETS}` }
  },
  {
    meta: {
      title: 'Quotes',
      requiredRoles: [USER_ROLES.REACH],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.QUOTES}`,
    name: 'Quotes',
    component: () => import('@/views/Quote/QuotesView.vue')
  },
  {
    meta: {
      title: 'Quote',
      requiredRoles: [USER_ROLES.REACH],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.QUOTES}/:id`,
    name: 'Quote',
    component: () => import('@/views/Quote/QuoteView.vue')
  },
  {
    meta: {
      title: 'Edit customer',
      requiredRoles: [USER_ROLES.REACH],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.QUOTES}/:id/${ROUTES.CUSTOMER}`,
    name: 'Edit customer',
    component: () => import('@/views/Quote/CustomerView.vue')
  },
  {
    meta: {
      title: 'Download quote',
      requiredRoles: [USER_ROLES.REACH],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.QUOTES}/:id/${ROUTES.DOWNLOAD}`,
    name: 'Download quote',
    component: () => import('@/views/Quote/DownloadQuoteView.vue')
  },
  {
    meta: {
      title: 'Design quote',
      requiredRoles: [USER_ROLES.REACH],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.QUOTES}/:id/${ROUTES.DESIGN}`,
    name: 'Design quote',
    component: () => import('@/views/Quote/DesignQuoteView.vue')
  },
  {
    meta: {
      title: 'Digital quote',
      requiredRoles: [USER_ROLES.REACH],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.QUOTES}/:id/${ROUTES.DIGITAL}`,
    name: 'Digital quote',
    component: () => import('@/views/Quote/DigitalQuoteView.vue')
  },
  {
    meta: {
      title: 'Letterbox quote',
      requiredRoles: [USER_ROLES.REACH],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.QUOTES}/:id/${ROUTES.LETTERBOX}`,
    name: 'Letterbox quote',
    component: () => import('@/views/Quote/LetterboxQuotesView.vue')
  },
  {
    meta: {
      title: 'Print quote',
      requiredRoles: [USER_ROLES.REACH],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.QUOTES}/:id/${ROUTES.PRINT}`,
    name: 'Print quote',
    component: () => import('@/views/Quote/PrintQuoteView.vue')
  },
  {
    meta: {
      title: 'Social media management quote',
      requiredRoles: [USER_ROLES.REACH],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.QUOTES}/:id/${ROUTES.SOCMED}`,
    name: 'Social media management quote',
    component: () => import('@/views/Quote/SocMedManagementView.vue')
  },
  {
    meta: {
      title: 'Website quote',
      requiredRoles: [USER_ROLES.REACH],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.QUOTES}/:id/${ROUTES.WEBSITE}`,
    name: 'Website quote',
    component: () => import('@/views/Quote/WebsiteQuoteView.vue')
  },
  {
    meta: {
      title: 'Design calculator',
      requiredRoles: [USER_ROLES.REACH],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.CALCULATOR}/${ROUTES.DESIGN}`,
    name: 'Design calculator',
    component: () => import('@/views/Calculator/DesignCalculatorView.vue')
  },
  {
    meta: {
      title: 'Digital calculator',
      requiredRoles: [USER_ROLES.REACH],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.CALCULATOR}/${ROUTES.DIGITAL}`,
    name: 'Digital calculator',
    component: () => import('@/views/Calculator/DigitalCalculatorView.vue')
  },
  {
    meta: {
      title: 'Print calculator',
      requiredRoles: [USER_ROLES.REACH],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.CALCULATOR}/${ROUTES.PRINT}`,
    name: 'Print calculator',
    component: () => import('@/views/Calculator/PrintCalculatorView.vue')
  },
  // {
  //   meta: {
  //     title: 'Jobs',
  //     requiresAuth: true
  //   },
  //   path: `${ROUTES.BASE}${ROUTES.JOBS}`,
  //   name: ROUTES.JOBS,
  //   component: () => import('@/views/Job/JobsView.vue')
  // },
  // {
  //   meta: {
  //     title: 'Job',
  //     requiresAuth: true
  //   },
  //   path: `${ROUTES.BASE}${ROUTES.JOBS}/:id`,
  //   name: 'job',
  //   component: () => import('@/views/Job/JobView.vue')
  // },
  {
    meta: {
      title: 'Tickets',
      requiredRoles: [USER_ROLES.ORG_ADMIN, USER_ROLES.CALL_CENTRE],
      requiresAuth: true
    },
    path: `${ROUTES.BASE}${ROUTES.TICKETS}`,
    name: ROUTES.TICKETS,
    component: () => import('@/views/Ticket/TicketsView.vue')
  },
  {
    meta: {
      title: 'Create ticket',
      requiredRoles: [USER_ROLES.ORG_ADMIN, USER_ROLES.CALL_CENTRE],
      requiresAuth: true
    },
    path: `${ROUTES.BASE}${ROUTES.CREATE_TICKET}`,
    name: 'Create ticket',
    component: () => import('@/views/Ticket/CreateTicketView.vue')
  },
  {
    meta: {
      title: 'Ticket',
      requiredRoles: [USER_ROLES.ORG_ADMIN, USER_ROLES.REACH],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.TICKETS}/:id`,
    name: 'ticket {id}',
    component: () => import('@/views/Ticket/TicketView.vue')
  },
  {
    meta: {
      title: 'Delivery Status',
      requiredRoles: [USER_ROLES.ORG_ADMIN],
      requiresAuth: true,
      useCustomTitle: true,
      requireQueryCheck: true,
      defaultFilter: getDeliveryFilter(),
      checkerKey: ROUTES.DELIVERY_STATUS
    },
    path: `${ROUTES.BASE}${ROUTES.DELIVERY_STATUS}`,
    name: ROUTES.DELIVERY_STATUS,
    component: () => import('@/views/Delivery/CustomerDeliveryStatusView.vue')
  },
  {
    meta: {
      title: 'Check Delivery Address',
      requiredRoles: [USER_ROLES.CALL_CENTRE],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.CHECK_DELIVERY_ADDRESS}`,
    name: ROUTES.CHECK_DELIVERY_ADDRESS,
    component: () => import('@/views/Delivery/CheckDeliveryAddressView.vue')
  },
  {
    meta: {
      title: 'Delivery Report',
      requiredRoles: [USER_ROLES.REACH],
      requiresAuth: true,
      useCustomTitle: true
    },
    path: `${ROUTES.BASE}${ROUTES.DELIVERY_REPORT}`,
    name: 'Delivery Report',
    component: () => import('@/views/Delivery/CXDeliveryView.vue')
  },
  {
    meta: {
      title: 'Login'
    },
    path: `${ROUTES.BASE}${ROUTES.LOGIN}`,
    name: ROUTES.LOGIN,
    component: () => import('@/views/LoginView.vue')
  },
  {
    meta: {
      title: 'Login'
    },
    path: `${ROUTES.BASE}${ROUTES.LOGIN}/${ROUTES.REACH}`,
    name: `${ROUTES.LOGIN}/${ROUTES.REACH}`,
    component: () => import('@/views/ReachLoginView.vue')
  },
  {
    path: `${ROUTES.BASE}:pathMatch(.*)`,
    name: ROUTES.ERROR,
    component: () => import('@/views/ErrorView.vue')
  }
]
