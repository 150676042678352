const isNullOrEmpty = (str) => {
    return str === null || str === undefined || (typeof str === 'string' && str.trim().length === 0);
}

export const isObjHasProperties = (sourceObj, referenceObj) => {
    return Object.keys(referenceObj).every((key) => Object.keys(sourceObj).includes(key));
}

export const removeNonExistentPropertiesWithDiff = (sourceObj, referenceObj) => {
    const result = {};
    const difference = {};

    Object.keys(sourceObj).forEach(key => {
        if (key in referenceObj) {
            result[key] = sourceObj[key];
        } else {
            difference[key] = sourceObj[key];
        }
    });

    return { filtered: result, removed: difference };
}

export const convertStringToDateProperty = (obj, properties = []) => {
    if (!obj || typeof obj !== 'object') {
        throw new Error('Invalid object provided');
    }
    for (let key of properties) {
        if (!obj.hasOwnProperty(key) || obj[key] == null) continue;
        if (typeof obj[key] !== 'string') {
            throw new Error(`Property "${property}" is not a string`);
        }
        obj[key] = new Date(obj[key]);
    }
    return obj;
};

export const removeUndefinedValue = (obj) => {
    Object.keys(obj).forEach(key => isNullOrEmpty(obj[key]) && delete obj[key]);
    return obj;
}
