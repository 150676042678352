import { DEFAULT_VIEW_PARAMS, DELIVERY_STATUS, ZONE_STORE_AREA } from "@/const/filter";
import { DELIVERY_SORT_PARAM, SORT_ORDER } from "@/const/sort";

const deliveryFilter = {
    ...DEFAULT_VIEW_PARAMS,
    status: [DELIVERY_STATUS.ALL]
}
export const getDeliveryFilter = () => ({
    ...deliveryFilter,
    contract_no: '',
    job: ZONE_STORE_AREA
});

export const getCmsDeliveryFilter = () => ({
    ...deliveryFilter,
    walkId: "",
    contract_no: '',
    cmsCode: null,
    job: ZONE_STORE_AREA,
    status: [],
    sortOrder: SORT_ORDER.ASCENDING,
    sortParam: DELIVERY_SORT_PARAM.ROUND_NAME
})

export const deliveryStatusOptions = () => {
    return Object.values(DELIVERY_STATUS)
        .filter((value) => value !== DELIVERY_STATUS.ALL)
        .map((value) => ({ value, label: value }));
};
