import { ENDPOINT, HTTP } from '@/const/http'
import { constructAuthOptions, useApi } from './common'

export const getPermissions = async () => {
  const { data, fetchApi } = useApi({
    optionsHandler: constructAuthOptions
  })

  await fetchApi({
    endPoint: ENDPOINT.GET_PERMISSIONS(),
    method: HTTP.GET
  })

  return data.value
}
